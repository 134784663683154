import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  alt?: string
  className?: string
  height?: number
  media?: string
  sources?: string
  src?: string
  srcSet?: string
  width?: number
}

export const Image = memo(function Image({
  alt,
  className,
  height,
  media,
  sources,
  src,
  srcSet,
  width,
}: Props) {
  return (
    <Picture className={className}>
      <Source srcSet={sources} media={media || undefined} />
      <Img src={src} srcSet={srcSet} alt={alt} width={width} height={height} />
    </Picture>
  )
})

const Picture = styled.picture``

const Source = styled.source``

const Img = styled.img``
